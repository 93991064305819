import { getCurrentPositionAsync, requestForegroundPermissionsAsync } from 'expo-location'
import alert from '../../alert'

export const getLocation = async () => {
    const { status } = await requestForegroundPermissionsAsync()
    if (status !== 'granted') {
        alert('位置情報の取得が許可されていません。', '位置情報の取得を許可してください。', [{ text: 'OK'}])
        return
    }
    const location = await getCurrentPositionAsync({})
    return location
}
