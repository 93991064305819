import React, { useState } from 'react'
import { View, Text, TextInput, StyleSheet, Pressable, Image } from 'react-native'
import { getAuth, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { login } from '../features/user/userSlice'
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../alert'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebaseConfig'
import Links from '../components/Links'

function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
}

const LoginScreen = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const auth = getAuth()
    const dispatch = useDispatch()
    const loginHandler = () => {
        setError('')
        if (email === '' || validateEmail(email) === false) {
            setError('正しいメールアドレスを入力してください。')
            return
        }
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                if (!userCredential.user.emailVerified) {
                    alert('メールの確認がが完了していません', 'メールのリンクをクリックして登録を完了してください。確認メールを再送信しますか？', [{ text: 'OK', onPress: () => { sendEmailVerification(userCredential.user) }}])
                    return
                }
                dispatch(login({
                    uid: userCredential.user.uid,
                    email: userCredential.user.email ?? ''
                }))
                AsyncStorage.setItem('user', JSON.stringify(userCredential.user))
                setError('')
                logEvent(analytics, 'login', { email: email })
            })
            .catch((error) => {
                setError('ログインに失敗しました')
                logEvent(analytics, 'error', { type: 'login', email, error })
            })
    }

    return (
        <View style={styles.container}>
            <View style={styles.container}>
                <View style={[styles.buttonLayout]}>
                    <Image style={styles.logo} source={require('../assets/kiroku_logo.png')} />
                </View>
                <View style={[styles.inputLayout]}>
                    <TextInput
                        style={[styles.input]}
                        onChangeText={newText => setEmail(newText)}
                        placeholder="email"
                        autoComplete='email'
                        keyboardType='email-address'
                    ></TextInput>
                </View>
                <View style={[styles.inputLayout]}>
                    <TextInput
                        style={[styles.input]}
                        onChangeText={newText => setPassword(newText)}
                        placeholder="password"
                        secureTextEntry={true}
                    ></TextInput>
                </View>
                <Text>{error}</Text>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={loginHandler} >
                        <Text style={[styles.text]}>ログイン</Text>
                    </Pressable>
                </View>
                <Links />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor: 'rgba(0, 172, 168, 0.15)',
        zIndex: -1,
        gap: 10
    },
    buttonLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        color: 'FFFFFF',
        backgroundColor: 'white',
    },
    text: {
        color: '#75655D',
        fontSize: 16,
        fontWeight: '500',
    },
    logo: {
        width: 183,
        height: 186,
        margin: 24
    },
    inputLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    input: {
        width: '100%',
        backgroundColor: 'white',
        paddingVertical: 12,
        paddingHorizontal: 12,
        borderRadius: 4,
    },
})

export default LoginScreen
