import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import React from 'react'
import { View, Text, StyleSheet, Pressable, Image, ScrollView } from 'react-native'
import { RootStackParamList } from '../App'
import { getAuth, signOut } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { logout } from '../features/user/userSlice'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Links from '../components/Links'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebaseConfig'

type ProfileScreenNavigationProp = NativeStackNavigationProp<
    RootStackParamList,
    'Menu'
>

const MenuScreen = () => {
    const navigation = useNavigation<ProfileScreenNavigationProp>()
    const dispatch = useDispatch()
    const auth = getAuth()
    const logoutHandler = async () => {
        signOut(auth).then(() => {
            dispatch(logout())
        })
            .catch((error) => {
                logEvent(analytics, 'error', { type: 'sign_out', error })
            })
        await AsyncStorage.removeItem('user')
    }
    return (
        <View style={[styles.container]}>
            <ScrollView contentContainerStyle={[styles.container]}>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={() => navigation.navigate('Agree')} >
                        <Image style={styles.logo} source={require('../assets/kiroku_logo.png')} />
                        <Text style={[styles.text]}>キロクする</Text>
                    </Pressable>
                </View>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={() => navigation.navigate('ReadQRcode')} >
                        <Text style={[styles.text]}>QRコードを読み取る</Text>
                    </Pressable>
                </View>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={() => navigation.navigate('History')} >
                        <Text style={[styles.text]}>キロク履歴を見る</Text>
                    </Pressable>
                </View>
                <Links />
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={logoutHandler} >
                        <Text style={[styles.text]}>ログアウト</Text>
                    </Pressable>
                </View>
            </ScrollView>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 172, 168,0.15)',
        zIndex: -1,
        gap: 10,
    },
    buttonLayout: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        flex: 1,
        flexGrow: 0.8,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        color: 'FFFFFF',
        backgroundColor: 'white',
    },
    text: {
        color: '#75655D',
        fontSize: 16,
        fontWeight: '500',
    },
    logo: {
        width: 183,
        height: 186,
        margin: 24
    },
    link: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    linkText: {
        color: 'blue',
        fontSize: 16,
        fontWeight: '500',
        textDecorationLine: 'underline'
    }
})

export default MenuScreen
