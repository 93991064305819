import React from 'react'
import { View, Text, StyleSheet, Image, Pressable } from 'react-native'
import { getDisplayDate } from '../features/date/date'
import { RootStackParamList } from '../App'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useNavigation } from '@react-navigation/native'

type ProfileScreenNavigationProp = NativeStackNavigationProp<
    RootStackParamList,
    'Agreed'
>

const AgreedScreen = () => {
    const navigation = useNavigation<ProfileScreenNavigationProp>()
    const date = new Date()
    return (
        <View style={styles.container}>
            <Text style={styles.date}>{getDisplayDate(date)}</Text>
            <Text style={styles.text}>キロク完了</Text>
            <Image style={styles.logo} source={require('../assets/kiroku_logo.png')} />
            <View style={styles.footer} >
                <Pressable style={[styles.button]} onPress={() => navigation.navigate('Menu')} >
                    <Text style={[styles.buttonText]}>メニューにもどる</Text>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 10,
    },
    date: {
        color: '#00ACA8',
        fontSize: 14,
        fontWeight: '800',
    },
    text: {
        color: '#75655D',
        fontSize: 36,
        fontWeight: '800',
    },
    logo: {
        width: 320,
        height: 320,
        marginTop: 24,
        marginBottom: 68,
    },
    footer: {
        backgroundColor: '#00ACA8',
        flexGrow: 1,
        position: 'absolute',
        bottom: 0,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 24,
        fontWeight: '800',
        padding: 32,
    }
})

export default AgreedScreen
