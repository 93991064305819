import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebaseConfig'

export class User {
    constructor(
        readonly uid: string,
        readonly email: string,
        readonly createdAt: Date = new Date()
    ) {}
    toString(): string {
        return `uid: ${this.uid}, email: ${this.email}, createdAt: ${this.createdAt.toISOString()}`
    }
}

const userConverter = {
    toFirestore(user: User): DocumentData {
        return {email: user.email, createdAt: user.createdAt}
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): User {
        const data = snapshot.data(options)!
        return new User(snapshot.id, data.email, new Date(data.createdAt.seconds * 1000),)
    }
}

export const getUser = async (uid: string): Promise<User | null> => {
    const docRef = doc(db, 'users', uid).withConverter(userConverter)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null
    }
}

export const createUser = async (user: User) => {
    await setDoc(doc(db, 'users', user.uid).withConverter(userConverter), user)
}
