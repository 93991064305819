import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Pressable, ScrollView, StyleSheet } from 'react-native'
import { View, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { getDisplayDate } from '../features/date/date'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RootStackParamList } from '../App'
import { Agreement, createAgreement, getAgreements } from '../models/agreement'
import alert from '../alert'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebaseConfig'

type ProfileScreenNavigationProp = NativeStackNavigationProp<
    RootStackParamList,
    'History'
>

const HistoryScreen = () => {
    const [isLoading, setIsLoadiong] = React.useState<boolean>(false)
    const navigation = useNavigation<ProfileScreenNavigationProp>()
    const user = useSelector((state: RootState) => state.user)
    if (!user.uid) navigation.navigate('Login')
    const [agrees, setAgrees] = useState<Agreement[]>([])
    useEffect(() => {
        getAgrees()
    }, [])
    const getAgrees = async () => {
        const agreements = await getAgreements(user.uid)
        // agreements.sort((a, b) => {
        //     return b.createdAt.getTime() - a.createdAt.getTime()
        // })
        setAgrees(agreements)
    }
    const handleHistoryPressed = async (agree: Agreement) => {
        if (!canCancel(agree)) return
        alert('', '本当に取り消しますか？', [
            { text: 'はい', onPress: async () => await handleCancel(agree) },
            { text: 'いいえ', style: 'cancel' },
        ])
    }
    const handleCancel = async (agree: Agreement) => {
        setIsLoadiong(true)
        const agreement = new Agreement(
            false,
            agree.proposalId,
            agree.proposedUserId,
            agree.proposedUserEmail,
            user.uid,
            user.email,
        )
        await createAgreement(user.uid, agreement)
        await createAgreement(agree.proposedUserId, agreement)

        await getAgrees()
        setIsLoadiong(false)
        logEvent(analytics, 'agree_canceled')
    }
    const canCancel = (agree: Agreement) => {
        // 既にキャンセル済み
        if (agree.isAgreed === false) return false
        // 自分が同意者でない
        if (agree.agreedUserId !== user.uid) return false
        const now = new Date()
        const diff = now.getTime() - agree.createdAt.getTime()
        // ３日以内はキャンセル可能
        return diff < 1000 * 60 * 60 * 24 * 3
    }

    return (
        <View style={styles.container}>
            {isLoading &&
                <View style={styles.loading}>
                    <ActivityIndicator size='large' />
                </View>
            }
            <ScrollView>
                {agrees.map((agree, index) => {
                    return (
                        <Pressable key={index} onPress={ () => handleHistoryPressed(agree)}>
                            <View style={styles.row}>
                                <View>
                                    <Text style={styles.text}>〉</Text>
                                </View>
                                <View style={styles.agreeArea} key={index}>
                                    <View style={styles.textArea}>
                                        <Text style={styles.text}>{getDisplayDate(agree.createdAt)}</Text>
                                    </View>
                                    <View style={styles.textArea}>
                                        <Text style={styles.text}>{agree.proposedUserId == user.uid ? agree.agreedUserEmail : agree.proposedUserEmail}</Text>
                                    </View>
                                    <View style={styles.textArea}>
                                        <Text style={styles.text}>{agree.isAgreed ? 'キロク済み' : 'キャンセル'}</Text>
                                    </View>
                                </View>
                            </View>
                        </Pressable>
                    )
                })}
            </ScrollView>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 172, 168, 0.15)',
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 20,
        marginVertical: 10,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderColor: '#75655D',
    },
    agreeArea: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        marginRight: 20,
    },
    textArea: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    text: {
        color: '#75655D',
        fontSize: 14,
        fontWeight: '800',
        marginBottom: 16,
    }
})


export default HistoryScreen
