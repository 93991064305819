import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { BarCodeScanningResult, Camera } from 'expo-camera'
import React, { useEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { RootStackParamList } from '../App'
import { getProposal } from '../models/proposal'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebaseConfig'

type ProfileScreenNavigationProp = NativeStackNavigationProp<
    RootStackParamList,
    'ReadQRcode'
>

const ReadQRcodeScreen = () => {
    const navigation = useNavigation<ProfileScreenNavigationProp>()
    const [hasPermission, setHasPermission] = useState<boolean | null>(null)
    const [scanned, setScanned] = useState(false)
    useEffect(() => {
        const getBarCodeScannerPermissions = async () => {
            const { status } = await Camera.requestCameraPermissionsAsync()
            setHasPermission(status === 'granted')
        }
    
        getBarCodeScannerPermissions()
        navigation.addListener('focus', () => {
            setScanned(false)
        })
    }, [])
    const handleBarCodeScanned = (event: BarCodeScanningResult) => {
        if (scanned) return
        if (validateQRcode(event.data) === false) {
            return
        }
        setScanned(true)
        logEvent(analytics, 'scan_ok')
        navigation.navigate('Agree', JSON.parse(event.data))
    }
    const validateQRcode = (data: string) => {
        try {
            const params = JSON.parse(data) as RootStackParamList['Agree']
            getProposal(params?.userId ?? '', params?.proposalId ?? '')
        } catch (error) {
            alert('不正なQRコードです')
            logEvent(analytics, 'error', { type: 'invalid_qrcode', error })
            return false
        }
        return true
    }
    if (hasPermission === null) {
        return <Text>Requesting for camera permission</Text>
    }
    if (hasPermission === false) {
        return <Text>No access to camera</Text>
    }
    return (
        <View style={styles.container}>
            <Text>QRcode読み取り画面</Text>
            <Camera
                barCodeScannerSettings={{
                    barCodeTypes: ['qr'],
                }}
                onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                style={StyleSheet.absoluteFillObject}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
})

export default ReadQRcodeScreen
