import React, { useState } from 'react'
import {StyleSheet, Text, View} from 'react-native'
import { CheckBox } from 'react-native-elements'

export type CheckBoxTextProps = {
    index: number
    title: string
    text: string
    handleChecked: (index: number, checked: boolean) => void
};


const CheckBoxText = (props: CheckBoxTextProps) => {
    const [checked, setCheck] = useState(false)
    const handleOnPress = () => {
        const value = !checked
        setCheck(value)
        props.handleChecked(props.index, value)
    }
    return(
        <View style={styles.container}>
            <View style={styles.titleArea}>
                <View style={styles.titleNumberArea}>
                    <Text style={styles.titleNumber}>{ props.index + 1 }</Text>
                </View>
                <Text style={styles.title}>{ props.title }</Text>
            </View>
            {checked === false && (
                <View style={styles.textArea}>
                    <Text>{ props.text }</Text>
                </View>
            )}
            <View style={styles.checkArea}>
                <CheckBox checked={checked} onPress={handleOnPress}></CheckBox>
                <Text>上記について確認しました</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    titleArea: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    titleNumberArea: {
        width: 40,
        height: 40,
        backgroundColor: '#00ACA8',
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    titleNumber: {
        fontSize: 24,
        fontWeight: '900',
        color: 'white',
    },
    title: {
        fontSize: 20,
        fontWeight: '900',
        paddingLeft: 10,
    },
    textArea: {
        borderWidth: 1,
    },
    checkArea: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    }
})

export default CheckBoxText
