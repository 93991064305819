import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

// Initialize Firebase
export const firebaseConfig = {
    apiKey: 'AIzaSyBlCYhDrOvj235YKCAwXmd2J7CgjJS4XkY',
    authDomain: 'kiroku-ac66a.firebaseapp.com',
    projectId: 'kiroku-ac66a',
    storageBucket: 'kiroku-ac66a.appspot.com',
    messagingSenderId: '684359424535',
    appId: '1:684359424535:web:1a404a587ea14670ef1a52',
    measurementId: 'G-9LCWJHQ6WM'
}

const app = initializeApp(firebaseConfig)
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
export const db = getFirestore()
export const analytics = getAnalytics(app)
