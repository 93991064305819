import React, { useState } from 'react'
import { View, Text, TextInput, StyleSheet, Pressable, Image } from 'react-native'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useNavigation } from '@react-navigation/native'
import { RootStackParamList } from '../App'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import alert from '../alert'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebaseConfig'
import Links from '../components/Links'

type ProfileScreenNavigationProp = NativeStackNavigationProp<
    RootStackParamList,
    'ResetPassword'
>

function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
}

const ResetPasswordScreen = () => {
    const navigation = useNavigation<ProfileScreenNavigationProp>()
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const auth = getAuth()

    const resetPasswordHandler = () => {
        setError('')
        if (email === '' || validateEmail(email) === false) {
            setError('正しいメールアドレスを入力してください。')
            return
        }
        alert('','パスワード再設定用のメールを送信しますか？', [
            { text: 'OK', onPress: () => {
                sendPasswordResetEmail(auth, email)
                    .catch((error) => {
                        logEvent(analytics, 'error', { type: 'reset_password', email, error })
                    })
                    .finally(() => {
                        alert('','メールを送信しました。', [{ text: 'OK', onPress: () => { console.log('OK') } }])
                        logEvent(analytics, 'reset_password', { email: email })
                        navigation.goBack()
                    })
            } }
        ])
    }
    return (
        <View style={styles.container}>
            <View style={styles.container}>
                <View style={[styles.buttonLayout]}>
                    <Image style={styles.logo} source={require('../assets/kiroku_logo.png')} />
                </View>
                <View style={[styles.inputLayout]}>
                    <TextInput
                        style={[styles.input]}
                        onChangeText={newText => setEmail(newText)}
                        placeholder="email"
                        autoComplete='email'
                        keyboardType='email-address'
                    ></TextInput>
                </View>
                <Text>{error}</Text>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={resetPasswordHandler} >
                        <Text style={[styles.text]}>リセットメール送信</Text>
                    </Pressable>
                </View>
                <Links />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor: 'rgba(0, 172, 168, 0.15)',
        zIndex: -1,
        gap: 10
    },
    buttonLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        color: 'FFFFFF',
        backgroundColor: 'white',
    },
    text: {
        color: '#75655D',
        fontSize: 16,
        fontWeight: '500',
    },
    logo: {
        width: 183,
        height: 186,
        margin: 24
    },
    inputLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    input: {
        width: '100%',
        backgroundColor: 'white',
        paddingVertical: 12,
        paddingHorizontal: 12,
        borderRadius: 4,
    },
    link: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    linkText: {
        color: 'blue',
        fontSize: 16,
        fontWeight: '500',
        textDecorationLine: 'underline'
    }
})

export default ResetPasswordScreen
