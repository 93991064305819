import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import LoginScreen from './pages/LoginScreen'
import AgreeScreen from './pages/AgreeScreen'
import HistoryScreen from './pages/HistoryScreen'
import { RootState, store } from './app/store'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { requestForegroundPermissionsAsync } from 'expo-location'
import MenuScreen from './pages/MenuScreen'
import QRcodeScreen from './pages/QRcodeScreen'
import AgreedScreen from './pages/AgreedScreen'
import ReadQRcodeScreen from './pages/ReadQRcodeScreen'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { login, logout } from './features/user/userSlice'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import TopScreen from './pages/TopScreen'
import SignupScreen from './pages/SignupScreen'
import ResetPasswordScreen from './pages/ResetPasswordScreen'
import { Helmet } from 'react-helmet'

// route
export type RootStackParamList = {
    Home: undefined
    Menu: undefined
    User: undefined
    Top: undefined
    Login: undefined
    Signup: undefined
    ResetPassword: undefined
    Agree: { userId: string, proposalId: string } | undefined
    Agreed: undefined
    History: undefined
    QRcode: { userId: string, proposalId: string } | undefined
    Location: undefined
    Counter: undefined
    ReadQRcode: undefined
};
const Stack = createNativeStackNavigator<RootStackParamList>()

export default function App() {
    return (
        <Provider store={store}>
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3207734938014783" crossOrigin="anonymous"></script>
            </Helmet>
            <AppChild />
        </Provider>
    )
}

function AppChild() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
        if (!user) {
            dispatch(logout())
        }
    })
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            const { status } = await requestForegroundPermissionsAsync()
            if (status !== 'granted') {
                return
            }
        })()
        AsyncStorage.getItem('user').then((value) => {
            try {
                const user = JSON.parse(value ?? '')
                dispatch(login({uid: user.uid, email: user.email}))
            } catch (error) {
                // 不正な値が入っているので削除
                AsyncStorage.removeItem('user')
            }
        })
    }, [])
    return (
        <NavigationContainer>
            {
                user.isLogin ? (
                    <Stack.Navigator initialRouteName="Menu">
                        <Stack.Screen name="Menu" component={MenuScreen} />
                        <Stack.Screen name="Agree" component={AgreeScreen} />
                        <Stack.Screen name="History" component={HistoryScreen} />
                        <Stack.Screen name="QRcode" component={QRcodeScreen} />
                        <Stack.Screen name="Agreed" component={AgreedScreen} />
                        <Stack.Screen name="ReadQRcode" component={ReadQRcodeScreen} />
                    </Stack.Navigator>
                ) : (
                    <Stack.Navigator initialRouteName="Top">
                        <Stack.Screen name="Top" component={TopScreen} />
                        <Stack.Screen name="Login" component={LoginScreen} />
                        <Stack.Screen name="Signup" component={SignupScreen} />
                        <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
                    </Stack.Navigator>
                )
            }
            
        </NavigationContainer>
    )
}
