import { NativeStackScreenProps } from '@react-navigation/native-stack'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import QRCode from 'react-native-qrcode-svg'
import { RootStackParamList } from '../App'
import { db } from '../firebaseConfig'
import { collection, onSnapshot } from 'firebase/firestore'

const QRcodeScreen = ({route, navigation}: NativeStackScreenProps<RootStackParamList, 'QRcode'>) => {
    if (route.params === undefined) {
        navigation.goBack()
    }
    const proposalRef = collection(db, 'users', route.params?.userId ?? '', 'proposals', route.params?.proposalId ?? '', 'agreements')
    onSnapshot(proposalRef, async (querySnapshot) => {
        if (querySnapshot.size === 0) {
            return
        }
        let isAgreed = true
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            data.isAgreed === false && (isAgreed = false)
        })
        if (isAgreed) {
            navigation.navigate('Agreed')
        }
    })
    return (
        <View style={styles.container}>
            <Text style={styles.text}>QRコードを 相手に読み込んでもらってください</Text>
            {route.params && route.params?.proposalId !== '' && (
                <View style={styles.qrcodeArea}>
                    <QRCode value={JSON.stringify(route.params)}/>
                </View>
            ) }
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    text: {
        fontSize: 18,
        fontWeight: '800',
        lineHeight: 24,
        textAlign: 'center',
        padding: 44,
    },
    qrcodeArea: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
})

export default QRcodeScreen
