import { DocumentData, GeoPoint, QueryDocumentSnapshot, SnapshotOptions, addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebaseConfig'

const COLLECTION_NAME = 'proposals'


export class Proposal {
    constructor(
        readonly proposedUserId: string,
        readonly proposedUserEmail: string,
        readonly location: {latitude: number, longitude: number},
        readonly createdAt: Date = new Date(),
        readonly updatedAt: Date = new Date(),
        readonly id: string = '',
    ) {}
    toString(): string {
        return `
        id: ${this.id},
        proposedUserId: ${this.proposedUserId},
        proposedUserEmail: ${this.proposedUserEmail},
        location: (latitude: ${this.location.latitude}, longitude: ${this.location.longitude}),
        createdAt: ${this.createdAt.toISOString()}
        updatedAt: ${this.updatedAt.toISOString()}
        `
    }
}

export const proposalConverter = {
    toFirestore(proposal: Proposal): DocumentData {
        return {
            proposedUserId: proposal.proposedUserId,
            proposedUserEmail: proposal.proposedUserEmail,
            location: new GeoPoint(proposal.location.latitude, proposal.location.longitude),
            createdAt: proposal.createdAt,
            updatedAt: proposal.updatedAt,
        }
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Proposal {
        const data = snapshot.data(options)!
        return new Proposal(
            data.proposedUserId,
            data.proposedUserEmail,
            data.location,
            new Date(data.createdAt.seconds * 1000),
            new Date(data.updatedAt.seconds * 1000),
            snapshot.id
        )
    }
}

export const createProposal = async (userId: string, proposal: Proposal) => {
    if (proposal.id !== '') {
        const proposalRef = doc(db, 'users', userId, COLLECTION_NAME, proposal.id).withConverter(proposalConverter)
        await setDoc(proposalRef, proposal)
        return proposal
    } else {
        const proposals = collection(db, 'users', userId, COLLECTION_NAME).withConverter(proposalConverter)
        const doc = await addDoc(proposals, proposal)
        return (await getDoc(doc)).data()
    }
}

export const getProposal = async (userId: string, proposalId: string) => {
    const proposalRef = doc(db, 'users', userId, COLLECTION_NAME, proposalId).withConverter(proposalConverter)
    const docment = await getDoc(proposalRef)
    return docment.data()
}
