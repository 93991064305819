import React, { useEffect, useState } from 'react'
import {Pressable, StyleSheet, Text, View} from 'react-native'
import { getPublicDocument } from '../models/public_documents'


const Links = () => {
    const [urlTerms, setUrlTerms] = useState('')
    const [urlPrivacy, setUrlPrivacy] = useState('')
    useEffect(() => {
        (async() => {
            setUrlTerms((await getPublicDocument('terms_of_service'))?.url ?? '')
            setUrlPrivacy((await getPublicDocument('privacy_policy'))?.url ?? '')
        })()
    }, [])
    return(
        <View style={[styles.container]}>
            <View style={[styles.buttonLayout]}>
                <Pressable style={[styles.link]} onPress={() => window.open(urlTerms)} >
                    <Text style={[styles.linkText]}>利用規約</Text>
                </Pressable>
            </View>
            <View style={[styles.buttonLayout]}>
                <Pressable style={[styles.link]} onPress={() => window.open(urlPrivacy)} >
                    <Text style={[styles.linkText]}>プライバシーポリシー</Text>
                </Pressable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5
    },
    buttonLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        color: 'FFFFFF',
        backgroundColor: 'white',
    },
    link: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    linkText: {
        color: 'blue',
        fontSize: 16,
        fontWeight: '500',
        textDecorationLine: 'underline'
    }
})

export default Links
