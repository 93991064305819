import React from 'react'
import { View, Text, StyleSheet, Pressable, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { RootStackParamList } from '../App'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import Links from '../components/Links'

type ProfileScreenNavigationProp = NativeStackNavigationProp<
    RootStackParamList,
    'Top'
>

const TopScreen = () => {
    const navigation = useNavigation<ProfileScreenNavigationProp>()
    return (
        <View style={styles.container}>
            <View style={styles.container}>
                <View style={[styles.buttonLayout]}>
                    <Image style={styles.logo} source={require('../assets/kiroku_logo.png')} />
                </View>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={() => navigation.navigate('Login')} >
                        <Text style={[styles.text]}>ログイン</Text>
                    </Pressable>
                </View>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={() => navigation.navigate('Signup')} >
                        <Text style={[styles.text]}>新規登録</Text>
                    </Pressable>
                </View>
                <View style={[styles.buttonLayout]}>
                    <Pressable style={[styles.button]} onPress={() => navigation.navigate('ResetPassword')} >
                        <Text style={[styles.text]}>パスワードを忘れた</Text>
                    </Pressable>
                </View>
                <Links />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor: 'rgba(0, 172, 168, 0.15)',
        zIndex: -1,
        gap: 10
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        paddingHorizontal: 32,
        borderRadius: 4,
        elevation: 3,
        color: 'FFFFFF',
        backgroundColor: 'white',
    },
    text: {
        color: '#75655D',
        fontSize: 16,
        fontWeight: '500',
    },
    logo: {
        width: 183,
        height: 186,
        margin: 24
    },
    inputLayout: {
        width: '80%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    input: {
        width: '100%',
        backgroundColor: 'white',
        paddingVertical: 12,
        paddingHorizontal: 12,
        borderRadius: 4,
    },
    link: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    linkText: {
        color: 'blue',
        fontSize: 16,
        fontWeight: '500',
        textDecorationLine: 'underline'
    }
})

export default TopScreen
